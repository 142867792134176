
/* DESKTOP */
@media (min-width:700px){
    .gridCompOuterDiv {
        margin: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 20%;
        background-color: #d9d9d9;
        border-radius: 15px;
    }
    .gridCompImageDiv {
        height: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 15px;
    }
    .gridCompImage {
        width: 90%;
        border-radius: 10px;
    }
    .gridCompTextDiv {
        padding-top: 15px;
        padding-bottom: 15px;
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .gridCompText {
        font-family: Gruppo;
        font-size: 25px;
        margin: 0px;
    }
}





/* MOBILE */
@media (max-width:700px){
    .gridCompOuterDiv {
        margin: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 85%;
        background-color: #d9d9d9;
        border-radius: 15px;
    }
    .gridCompImageDiv {
        padding-top: 15px;
        height: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .gridCompImage {
        width: 90%;
        border-radius: 10px;
    }
    .gridCompTextDiv {
        padding: 15px 10px 15px 10px;
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .gridCompText {
        font-family: Gruppo;
        font-size: 25px;
        margin: 0px;
    }
}