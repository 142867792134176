/* DESKTOP */
@media (min-width:700px){
    .homePageHeaderDiv {
        padding: 10px 50px 10px 50px;
    }
    .homeHeader {
        font-family: Gruppo;
        font-size: 25px;
    }
}







    /* MOBILE */
@media (max-width:700px){
    .homePageHeaderDiv {
        padding: 0px 10px 0px 10px;
    }
    .homeHeader {
        font-family: Gruppo;
        font-size: 20px;
    }
    }