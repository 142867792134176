/* DESKTOP */
@media (min-width:700px){
    .productsMainDiv {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .productsGridDiv {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .productsHeader {
        font-family: Gruppo;
        font-size: 50px;
      }

}







    /* MOBILE */
@media (max-width:700px){
    .productsHeader {
        font-family: Gruppo;
        font-size: 40px;
      }
    .productsGridDiv {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    }