/* DESKTOP */
@media (min-width:700px){
  .mainSingleProductDiv{
    flex-direction: column;
    justify-content: center;
  }
  .productHeader {
    font-family: Gruppo;
    font-size: 50px;
  }
  .productHeaderCode{
    font-family: Gruppo;
    font-size: 30px;
  }
  .productSingleHeader {
    font-family: Gruppo;
    font-size: 26px;
  }
  .productMainImg {
    width: 30%;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .productDescriptionTextDiv {
    padding: 20px 60px 0px 60px;
  }
  .productDescriptionText, .productDescriptionTextHeader {
    font-family: Gruppo;
    font-size: 30px;
  }
  .productDescriptionTextHeader {
    font-weight: 700;
  }
  .outerThicknessDiv {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 6px;
  }
  .singleThicknessDiv {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 20px;
  }
}

    /* MOBILE */
@media (max-width:700px){
  .mainSingleProductDiv{
    flex-direction: column;
    justify-content: center;
  }
  .productHeader {
    font-family: Gruppo;
    font-size: 40px;
  }
  .productHeaderCode{
    font-family: Gruppo;
    font-size: 25px;
  }
  .productSingleHeader {
    font-family: Gruppo;
    font-size: 30px;
  }
  .productMainImg {
    width: 90%;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .productDescriptionTextDiv {
    padding: 10px 10px 10px 10px;
  }
  .productDescriptionText, .productDescriptionTextHeader {
    font-family: Gruppo;
    font-size: 20px;
  }
  .productDescriptionTextHeader {
    font-weight: 700;
  }
  .outerThicknessDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 2px;
    font-size: 14px;
  }
  .singleThicknessDiv {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 20px;
  }
}