/* DESKTOP */
@media (min-width:700px){
    .allAvaDiv{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .allAvaBackgroundDiv {
        background-color: #e6e6e6;
        border-radius: 8px;
        width: 60%;
        padding: 20px;
    }
    .allAvaHeader {
        font-family: Gruppo;
        font-size: 25px;
    }
    .allAvaMiniHeader {
        font-family: Gruppo;
        font-size: 20px;
    }
    .allAvaLogo {
        width: 45%;
    }
    .allAvaText {
        font-family: Gruppo;
        font-size: 20px;
    }
}




    /* MOBILE */
@media (max-width:700px){
    .allAvaDiv{
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .allAvaBackgroundDiv {
        background-color: #e6e6e6;
        border-radius: 8px;
        width: 85%;
        padding: 10px;
    }
    .allAvaHeader {
        font-family: Gruppo;
        font-size: 25px;
        margin: 0px;
    }
    .allAvaMiniHeader {
        font-family: Gruppo;
        font-size: 20px;
    }
    .allAvaLogo {
        width: 100%;
    }
    .allAvaText {
        font-family: Gruppo;
        font-size: 20px;
    }
  }