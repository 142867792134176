/* DESKTOP */
@media (min-width:700px){
    .wtbMainDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        justify-content: center;
    }
    .wtbHeader {
        font-family: Gruppo;
        font-size: 50px;
      }
    .wtbCompanyLogo {
        width: 30%;
        margin-top: 60px;
      }

}







    /* MOBILE */
@media (max-width:700px){
    .wtbMainDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        justify-content: center;
    }
    .wtbHeader {
        font-family: Gruppo;
        font-size: 40px;
        margin-bottom: 0px;
    }
    .wtbCompanyLogo {
        width: 80%;
        margin-top: 40px;
      }
}