/* DESKTOP */
@media (min-width:700px){
    .contactFormMainDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        justify-content: center;
    }
    .contactFormHeader {
      font-family: Gruppo;
      font-size: 50px;
    }
    .formDiv {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .formSelect {
        width: 100%;
        font-size: 15px;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
    .formLabel {
      font-family: Gruppo;
      font-size: 24px;
      }
    .formInput, .formTextArea, .formSelect {
      font-family: Gruppo;
      font-size: 20px;
      font-weight: 600;
    }
    .formInput {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
      .formTextArea {
        width: 100%;
        height: 140px;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      }
      .formSubmit {
        font-size: 25px;
        font-family: Gruppo;
        width: 100%;
        background-color: #4CAF50;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
      .formSubmit:hover {
        background-color: #45a049;
      }
      .formDiv {
        border-radius: 5px;
        background-color: #f2f2f2;
        padding: 20px;
      }
      .errorText {
        color: red;
        margin: 0px;
        padding: 0px 0px 10px 0px;
      }
      .formSuccessDiv {
        background-color: #c6ecc6;
      }
      .formFailedDiv {
        background-color: #ff9999;
      }
      .formSuccessDiv, .formFailedDiv {
        width: 60%;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .formResultHeader, .formResultText {
        font-family: Gruppo;
        padding-left: 30px;
        padding-right: 30px;
      }
      .formResultHeader {
        font-size: 60px;
      }
      .formResultText {
        font-size: 40px;
      }
}







    /* MOBILE */
@media (max-width:700px){
  .contactFormMainDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    justify-content: center;
}
.contactFormHeader {
  font-family: Gruppo;
  font-size: 40px;
}
.formDiv {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formSelect {
    width: 100%;
    text-align: center;
    font-size: 15px;
    padding: 12px 20px;
    height: 40px;
    margin: 8px 0px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
.formInput {
    width: 100%;
    text-align: center;
    font-size: 15px;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .formTextArea {
    width: 100%;
    height: 100px;
    font-size: 15px;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .formSubmit {
    width: 100%;
    font-size: 17px;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-family: Gruppo;
    font-size: 22px;
    -webkit-appearance: none;
  }
  .formLabel {
    font-family: Gruppo;
    font-size: 20px;
    }
  .formSubmit:hover {
    background-color: #45a049;
  }
  .formDiv {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }
  .formInput, .formTextArea, .formSelect {
    font-family: Gruppo;
    font-size: 16px;
    font-weight: 600;
  }
  .errorText {
    color: red;
    margin: 0px;
    padding: 0px 0px 10px 0px;
  }
  .formSuccessDiv {
    background-color: #c6ecc6;
  }
  .formFailedDiv {
    background-color: #ff9999;
  }
  .formSuccessDiv, .formFailedDiv {
    width: 90%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .formResultHeader, .formResultText {
    font-family: Gruppo;
    padding-left: 15px;
    padding-right: 15px;
  }
  .formResultHeader {
    font-size: 32px;
  }
  .formResultText {
    font-size: 22px;
  }
    }