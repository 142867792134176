/* DESKTOP */
@media (min-width:700px){
.App {
  text-align: center;
  height: 100%;
}
.MainBodyDiv {
  margin-left: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-logo {
  width: 35%;
  pointer-events: none;
  margin-top: 25px;
}
.App-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.headingDiv {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.mainHeading {
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 30px;
}
.site-footer {
  margin-left: 275px;
}

}


