/* MOBILE */
@media (max-width:700px){
.App {
  text-align: center;
}
.MainBodyDiv {
  margin-left: 50px;
}
.main-logo {
  width: 80%;
  pointer-events: none;
  margin-top: 25px;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.headingDiv {
  margin-top: 10px;
}
.mainHeading {
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 20px;
  margin: 0px;
}
.site-footer {
  margin-left: 50px;
}

}

